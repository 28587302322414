import { getEcwidAppID } from '../constants/ecwidAppID';

async function initEcwidApp(isAutoLoaded = true, isGetPayload = true) {
  const appID = getEcwidAppID();
  const EcwidApp = window.EcwidApp;
  let payload = null;
  if (EcwidApp) {
    EcwidApp.init({
      app_id: appID,
      autoloadedflag: isAutoLoaded,
      autoheight: true,
    });

    if (isGetPayload) {
      const ecwidPaload = EcwidApp.getPayload();
      if (ecwidPaload) {
        payload = {};
        const { store_id, access_token, lang, public_token, app_state } = ecwidPaload;
        payload.storeID = store_id;
        payload.accessToken = access_token;
        payload.lang = lang;

        if (public_token !== undefined) {
          payload.publicToken = public_token;
        }

        if (app_state !== undefined) {
          payload.appState = app_state;
        }
      }
    }
  }
  return { payload, EcwidApp };
}

export default initEcwidApp;