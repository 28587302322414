import store from '../store';
import { enableMainPreloader } from '../store/slices/mainPreloaderSlice.js';

/**
 * @typedef MainPreloaderController
 * @type {object}
 * @memberOf module:utils
 * @property {function} showMainPreloader - display main preloader
 * @property {function} hideMainPreloader - hide main preloader
 * */

/** @type {MainPreloaderController} */
export default {
  showMainPreloader() {
    store.dispatch(enableMainPreloader(true));
  },
  hideMainPreloader() {
    store.dispatch(enableMainPreloader(false));
  },
};
