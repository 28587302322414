import AbortError from '../errors/AbortError';

/**
 * Abstract class representing a Repository
 * @memberOf module:api
 * */
class Repository {
  /**
   * Create Repository instance
   *
   * @param {string} basePath - Base api url for requests sent through this repository
   * @param {Service[]} services - Services required by the repository to send requests
   * */
  constructor(basePath, services) {
    this._basePath = basePath;
    this._services = services;
    this._request = this._request.bind(this);
    this._requestAborted = this._requestAborted.bind(this);
    this.abortedRequestControllers = new Map();

    /**
     * Sends request
     *
     * @function
     * @param {Service} service - Service for sending request
     * @param {Object} data - Request payload
     * @property {function} get - Send GET request
     * @property {function} post - Send POST request
     * @property {function} put - Send PUT request
     * @property {function} delete - Send DELETE request
     * */
    this.request = this._initRequest();

    /**
     * Sends a request. Interrupts the previous one if it hasn't been completed yet at the time of sending a new one.
     *
     * @function
     * @param {string} key - Unique request key
     * @param {Service} service - Service for sending request
     * @param {Object} data - Request payload
     * @property {function} get - Send GET request
     * @property {function} post - Send POST request
     * @property {function} put - Send PUT request
     * @property {function} delete - Send DELETE request
     * */
    this.requestAborted = this._initRequest(true);
  }

  get services() {
    return this._services;
  }

  set services(services) {
    this._services = services;
  }

  setAbortedRequestController(key, controller) {
    this.abortedRequestControllers.set(key, controller);
  }

  deleteAbortedRequestController(key) {
    this.abortedRequestControllers.delete(key);
  }

  _initRequest(isAborted = false) {
    const request = isAborted ? this._requestAborted : this._request;
    function getRequestWithMethod(method) {
      function createDataWithMethod(data) {
        return { ...data, method };
      }
      if (isAborted) {
        return function (key, service, data) {
          const requestData = createDataWithMethod(data);
          return request(key, service, requestData);
        };
      } else {
        return function (service, data) {
          const requestData = createDataWithMethod(data);
          return request(service, requestData);
        };
      }
    }

    request.post = getRequestWithMethod('post');
    request.get = getRequestWithMethod('get');
    request.put = getRequestWithMethod('put');
    request.delete = getRequestWithMethod('delete');
    return request;
  }

  async _request(service, data) {
    const trimmedUrl = data.url ? data.url.replace(/\/{2,}/, '/') : '';
    data.url = `${this._basePath ? this._basePath : ''}${trimmedUrl ? `/${trimmedUrl}` : ''}`;
    return await service.request(data);
  }

  async _requestAborted(key, service, data) {
    const controller = new AbortController();
    if (this.abortedRequestControllers.has(key)) {
      this.abortedRequestControllers.get(key).abort();
    }
    this.setAbortedRequestController(key, controller);
    try {
      const response = await this._request(service, { ...data, signal: controller.signal });
      this.deleteAbortedRequestController(key);
      return response;
    } catch (e) {
      if (!(e instanceof AbortError)) {
        this.deleteAbortedRequestController(key);
      }
      throw e;
    }
  }
}

export default Repository;
