import ExampleRepository from './ExampleRepository';
import PromotionRepository from './PromotionRepository';
import EcwidRepository from './EcwidRepository';
import apiURLs from '../../constants/apiURLs';

function createRepositories(services) {
  const specificServices = getRepositorySpecificServices(services);
  return {
    exampleRepository: new ExampleRepository(
      'example-path',
      specificServices.exampleRepositoryServices
    ),
    promotionRepository: new PromotionRepository(
      apiURLs.promotion.basePrefix,
      specificServices.promotionRepositoryServices
    ),
    ecwidRepository: new EcwidRepository(
      apiURLs.ecwid.basePrefix,
      specificServices.ecwidRepositoryServices
    ),
  };
}

function getRepositorySpecificServices(services) {
  const { exampleService, baseService } = services;
  const exampleRepositoryServices = {
    exampleService,
  };
  const promotionRepositoryServices = {
    baseService,
  };
  const ecwidRepositoryServices = {
    baseService,
  };
  return {
    exampleRepositoryServices,
    promotionRepositoryServices,
    ecwidRepositoryServices,
  };
}

export default createRepositories;
