import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  formatsAndUnits: {},
};

const profileSlice = createSlice({
  name: 'profileSlice',
  initialState,
  reducers: {
    setProfileData(state, action) {
      const { formatsAndUnits, account } = action.payload;
      const { currency, currencySuffix, currencyPrefix } = formatsAndUnits;
      const currencySign = currencySuffix || currencyPrefix;
      const { whiteLabel } = account;
      state.formatsAndUnits = {
        currency,
        currencySuffix,
        currencyPrefix,
        currencySign,
      };
      state.account = { whiteLabel };
    },
  },
});

const { reducer, actions } = profileSlice;
const { setProfileData } = actions;

export { setProfileData };
export default reducer;
