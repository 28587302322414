let baseApiUrl = '';

function setBaseApiUrl(apiUrl) {
  if (typeof apiUrl === 'string') {
    baseApiUrl = apiUrl;
  } else {
    throw new Error('setBaseApiUrl argument must be a string type');
  }
}

function getBaseApiUrl() {
  return baseApiUrl;
}

export { setBaseApiUrl, getBaseApiUrl };
