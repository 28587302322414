import dayjs from 'dayjs';
import store from '../store';
import getSearchParams from '../utils/getSearchParams';
import searchParamsConstant from '../constants/searchParams';
import { getFullDate } from '../utils/dateUtils';

const promotionsDTO = {
  getPromotionsList(promotionsListRemote) {
    return promotionsListRemote.map((promotionRemote) =>
      this.promotionRemoteToPromotionShort(promotionRemote)
    );
  },
  getPromotion(promotionRemote) {
    return this.promotionRemoteToPromotion(promotionRemote);
  },
  createTimeSlot(timeSlotRemote) {
    return this.timeSlotsRemoteToTimeSlots(timeSlotRemote);
  },
  getTimeSlot(timeSlotRemote) {
    return this.timeSlotsRemoteToTimeSlots(timeSlotRemote);
  },
  setPromotionSettings(promotion) {
    return this.promotionToPromotionRemote(promotion);
  },
  promotionRemoteToPromotionShort(promotionRemote) {
    const { id, enable, description } = promotionRemote;
    return {
      id,
      enable,
      description,
    };
  },
  createPromotion(promotionRemote) {
    return this.promotionRemoteToPromotion(promotionRemote);
  },
  setTimeSlots(timeSlotsData) {
    const {
      id,
      dateFrom,
      dateTo,
      timeFrom,
      timeTo,
      restrictDate,
      restrictTime,
      repeat,
      startDay,
    } = timeSlotsData;
    let startAtDate = null;
    let endAtDate = null;
    let restrictDayDate = null;
    if (dateFrom) {
      const formatDateFrom = new Date(dateFrom);
      const formatTimeFrom = new Date(timeFrom);
      const hours = formatTimeFrom.getHours();
      const minutes = formatTimeFrom.getMinutes();
      const combinedDateTime = new Date(
        formatDateFrom.getFullYear(),
        formatDateFrom.getMonth(),
        formatDateFrom.getDate(),
        hours,
        minutes
      );
      startAtDate = dayjs(combinedDateTime).toISOString();
    }
    if (dateTo) {
      const formatDateTo = new Date(dateTo);
      const formatTimeTo = new Date(timeTo);
      const hours = formatTimeTo.getHours();
      const minutes = formatTimeTo.getMinutes();
      const combinedDateTime = new Date(
        formatDateTo.getFullYear(),
        formatDateTo.getMonth(),
        formatDateTo.getDate(),
        hours,
        minutes
      );
      endAtDate = dayjs(combinedDateTime).toISOString();
    }
    if (restrictDate) {
      let fullDateRestrict = getFullDate(restrictTime, restrictDate);
      restrictDayDate = dayjs(fullDateRestrict).format('YYYY-MM-DD HH:mm:ssZ');
    }
    return {
      id,
      startAt: startAtDate,
      endAt: endAtDate,
      repeatStopAt: restrictDayDate,
      repeat,
      weeklyRepeatStartDays: startDay,
    };
  },
  timeSlotsRemoteToTimeSlots(timeSlotRemote) {
    const { id, startAt, endAt, repeat, weeklyRepeatStartDays, repeatStopAt } =
      timeSlotRemote;
    let startAtDate;
    let endAtDate;
    let restrictDayDate;
    let ignoreFrom = true;
    let ignoreTo = true;
    let ignoreRestrict = true;
    if (startAt) {
      startAtDate = new Date(startAt);
      ignoreFrom = false;
    }
    if (endAt) {
      endAtDate = new Date(endAt);
      ignoreTo = false;
    }
    if (repeatStopAt) {
      restrictDayDate = new Date(repeatStopAt);
      ignoreRestrict = false;
    }
    return {
      id,
      dateFrom: startAtDate,
      dateTo: endAtDate,
      timeFrom: startAtDate,
      timeTo: endAtDate,
      restrictDate: restrictDayDate,
      restrictTime: restrictDayDate,
      repeat,
      startDay: weeklyRepeatStartDays,
      ignoreFrom,
      ignoreTo,
      ignoreRestrict,
    };
  },
  promotionRemoteToPromotion(promotionRemote) {
    const {
      id,
      enable,
      parameter,
      description,
      categories,
      ribbons,
      timeSlots: timeSlotsRemote,
    } = promotionRemote;
    let categoriesToBuy = categories ?? [];
    let timeSlots = timeSlotsRemote ?? [];
    let products = promotionRemote.products ?? { items: [] };
    const { name: parameterName, value: parameterValue } = parameter;
    const { items: productsToBuy } = products;
    const { during, before } = ribbons;
    const { currencySuffix, currencyPrefix } =
      store.getState().profileReducer.formatsAndUnits;
    return {
      id,
      enable,
      meaning: String(parameterValue),
      description: description,
      parameter: parameterName,
      productsToBuy: productsToBuy.map((productToBuyRemote) =>
        this.productRemoteToProduct(productToBuyRemote, {
          currencyPrefix,
          currencySuffix,
        })
      ),
      categoriesToBuy: categoriesToBuy,
      ribbonBeforePromotion: before,
      ribbonDuringPromotion: during,
      timeSlots: timeSlots
        .map((timeSlotRemote) =>
          this.timeSlotsRemoteToTimeSlots(timeSlotRemote)
        )
        .sort((a, b) => (a.id > b.id ? 1 : -1)),
    };
  },
  setCategories(categoriesData) {
    const { categoriesToBuy } = categoriesData;
    let categories;
    if (categoriesToBuy !== undefined && Array.isArray(categoriesToBuy)) {
      categories = categoriesToBuy;
    }
    return {
      categoryIds: categories.map((category) => category.id),
    };
  },
  promotionToPromotionRemote(promotion) {
    const {
      description,
      parameter,
      meaning,
      dateFrom,
      timeFrom,
      dateTo,
      timeTo,
    } = promotion;
    const promotionRemote = {};
    if (description !== undefined) {
      promotionRemote.description = description;
    }
    if (dateFrom) {
      let fullDateFrom = getFullDate(timeFrom, dateFrom);
      promotionRemote.startAt = dayjs(fullDateFrom).format(
        'YYYY-MM-DD HH:mm:ssZ'
      );
    }
    if (dateTo) {
      let fullDateTo = getFullDate(timeTo, dateTo);
      promotionRemote.endAt = dayjs(fullDateTo).format('YYYY-MM-DD HH:mm:ssZ');
    }
    if (parameter !== undefined && meaning !== undefined) {
      promotionRemote.parameter = {
        name: parameter.key,
        value: parseFloat(meaning || '0'),
      };
    }
    return promotionRemote;
  },
  productRemoteToProduct(productRemote, currency) {
    const { id, name, originalImageUrl, sku, price } = productRemote;
    let currencyPrefix;
    let currencySuffix;
    if (!currency) {
      const { formatsAndUnits } = store.getState().profileReducer;
      currencyPrefix = formatsAndUnits.currencyPrefix;
      currencySuffix = formatsAndUnits.currencySuffix;
    } else {
      currencyPrefix = currency.currencyPrefix;
      currencySuffix = currency.currencySuffix;
    }
    const priceWithCurrency = `${currencyPrefix} ${price} ${currencySuffix}`;
    return {
      id,
      name,
      imageUrl: originalImageUrl || '',
      vendorCode: sku,
      price: priceWithCurrency,
    };
  },
  getProductsList: {
    remoteToDomain(products, pagination) {
      const { currencySuffix, currencyPrefix } =
        store.getState().profileReducer.formatsAndUnits;
      const {
        currentPage,
        lastPage: totalPages,
        perPage: itemsPerPage,
      } = pagination ?? {
        currentPage: 1,
        totalPages: 1,
        itemsPerPage: 20,
      };
      let curPage = parseInt(currentPage);
      return {
        pagination: {
          currentPage: curPage,
          totalPages,
          itemsPerPage,
        },
        products: products.map((product) =>
          promotionsDTO.productRemoteToProduct(product, {
            currencyPrefix,
            currencySuffix,
          })
        ),
      };
    },
    params(promotionID, search, page) {
      const params = {};
      if (!promotionID) {
        params.promotionID = promotionID;
      } else {
        params.promotionID =
          getSearchParams(searchParamsConstant.promotionID) ?? '';
      }
      params.search = search ?? '';
      params.page = page ?? 1;
      return params;
    },
  },
  getCategoriesList: {
    remoteToDomain(categoriesRemote) {
      return categoriesRemote;
    },
    params(search) {
      const params = {};
      params.search = search ?? '';
      return params;
    },
  },
  setProducts(productsData) {
    const { productsToBuy } = productsData;
    let products = [];
    if (
      productsToBuy !== undefined &&
      Array.isArray(productsToBuy) &&
      productsToBuy.length
    ) {
      products = productsToBuy;
    }
    return {
      productIds: products.map((product) => product.id),
    };
  },
};

export default promotionsDTO;
