import Repository from './Repository';
import apiURLs from '../../constants/apiURLs';

class EcwidRepository extends Repository {
  async getProfile() {
    const data = {
      url: apiURLs.ecwid.getProfile,
    };
    const response = await this.request.get(this.services.baseService, data);
    return response.data;
  }
}

export default EcwidRepository;
