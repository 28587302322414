import { RESPONSE_ERROR } from './errorTypes';

class ResponseError extends Error {
  constructor(body, message) {
    super(message);
    this._type = RESPONSE_ERROR;
    this._body = body;
  }

  get type() {
    return this._type;
  }

  get body() {
    return this._body;
  }
}

export default ResponseError;
