/**
 * Get full date from separate date and time
 * @memberOf module:utils
 *
 * @param {Date|String} time
 * @param date
 * */
export const getFullDate = (time, date) => {
  let timeTemp = new Date(time).getHours();
  let dateTemp = new Date(date).setHours(timeTemp);
  return new Date(dateTemp);
};

