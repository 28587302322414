import transform from 'lodash.transform';

const caseDirections = {
  toCamel: 'toCamel',
  toSnake: 'toSnake',
};

class TransformCase {
  _currentCaseDirection = null;
  constructor() {
    this.snakeToCamel = this.snakeToCamel.bind(this);
    this.camelToSnake = this.camelToSnake.bind(this);
    this._transformCallback = this._transformCallback.bind(this);
  }

  static _transformString(str, direction) {
    const replaceOptions = {
      [caseDirections.toCamel]: {
        regular: /([_][a-z])/gi,
        replacer: (letter) => letter.toUpperCase().replace('_', ''),
      },
      [caseDirections.toSnake]: {
        regular: /([A-Z])/g,
        replacer: (letter) => `_${letter.toLowerCase()}`,
      },
    };
    return str.replace(replaceOptions[direction].regular, replaceOptions[direction].replacer);
  }

  snakeToCamel(value) {
    return this._transformCase(value, caseDirections.toCamel);
  }

  camelToSnake(value) {
    return this._transformCase(value, caseDirections.toSnake);
  }

  _transformCase(value, caseDirection) {
    this._currentCaseDirection = caseDirection;
    let result;
    if (Array.isArray(value)) {
      result = [];
    } else {
      result = {};
    }
    const transformedValue = transform(value, this._transformCallback, result);
    this._currentCaseDirection = null;
    return transformedValue;
  }

  _transformCallback(accumulator, value, key) {
    let camelKey = key;
    let newValue = value;
    let resultAccumulator;

    function isArray(value) {
      return Array.isArray(value);
    }

    function isObject(value) {
      return !!(value && typeof value === 'object');
    }

    const isValueArray = isArray(value);
    const isValueObject = isObject(value);
    const isAccumulatorArray = isArray(accumulator);

    if (typeof key === 'string') {
      const direction = this._currentCaseDirection ?? 'toCamel';
      camelKey = TransformCase._transformString(key, direction);
    }

    if (isValueObject || isValueArray) {
      let result = {};
      if (isValueArray) {
        result = [];
      }
      newValue = transform(value, this._transformCallback, result);
    }

    if (isAccumulatorArray) {
      resultAccumulator = accumulator;
      resultAccumulator.push(newValue);
    } else {
      resultAccumulator = accumulator;
      resultAccumulator[camelKey] = newValue;
    }
    return resultAccumulator;
  }
}
const transformCase = new TransformCase();
const { snakeToCamel, camelToSnake } = transformCase;

export { snakeToCamel, camelToSnake };
