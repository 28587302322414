import { combineReducers } from '@reduxjs/toolkit';
import applicationReducer from '../slices/applicationSlice';
import mainPreloaderReducer from '../slices/mainPreloaderSlice';
import profileReducer from '../slices/profileSlice';

export default combineReducers({
  applicationReducer,
  mainPreloaderReducer,
  profileReducer
});
