import { ABORT_ERROR } from './errorTypes';

class AbortError extends Error {
  constructor(message) {
    super(message);
    this._type = ABORT_ERROR;
    this._body = {};
  }

  get type() {
    return this._type;
  }

  get body() {
    return this._body;
  }
}

export default AbortError;
