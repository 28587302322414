import billings from '../constants/billings';
import { setEcwidAppID } from '../constants/ecwidAppID';
import environment from '../constants/environment';

function initEcwidAppID(billing) {
  switch (billing) {
    case billings.ext:
      setEcwidAppID(environment.ECWID_APP_ID_EXT);
      break;
    case billings.int:
      setEcwidAppID(environment.ECWID_APP_ID_INT);
      break;
    // no default
  }
}

export default initEcwidAppID;
