let ecwidAppID = '';

function setEcwidAppID(appID) {
  ecwidAppID = appID;
}

function getEcwidAppID() {
  return ecwidAppID;
}

export { setEcwidAppID, getEcwidAppID };
