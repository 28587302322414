let baseAppPath = '';

function setBaseAppPath(pathString) {
  if (typeof pathString === 'string') {
    baseAppPath = pathString;
  } else {
    throw new Error('setBasePath argument must be a string type');
  }
}

function getBaseAppPath() {
  return baseAppPath;
}

export { setBaseAppPath, getBaseAppPath };
