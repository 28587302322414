import SUBSTITUTION_TYPES from "../utils/substituteLocales/substitutionTypes";
import {faqLinks} from "../constants/links";

export default {
  commonTexts: {
    button: {
      save: 'Save',
      cancel: 'Cancel',
      back: 'Back',
    },
    removeModal: {
      label: 'Do you really wish to delete the ribbon?',
      agreeButton: 'Yes',
      cancelButton: 'No',
    },
    removeModal_HomePage: {
      label: 'Do you really wish to delete the promotion?',
      agreeButton: 'Yes',
      cancelButton: 'No',
    },
    tableProductsHeader: {
      name: 'Name',
      vendorCode: 'Vendor code',
      price: 'Price',
      action: '',
    },
    multiselect: {
      submitButton: 'Add to actions',
      cancelButton: 'Cancel',
      selectAllButton: 'Select all',
      selectNoneButton: 'Select none',
      products: {
        placeholder: 'Product name, SKU, UPC',
        pageText: 'Page',
        emptyList: {
          title: "There isn't such product",
          description:
            'Try a different name or clear your search to see all products',
        },
      },
      categories: {
        placeholder: 'Category title',
        emptyList: {
          title: "There isn't such category",
          description:
            'Try a different name or clear your search to see all categories',
        },
      },
    },
  },
  indexPage: {
    bundlesAreaTitle: 'Product bundles',
    bundlesAreaSubtitle:
      'You can edit and delete bundles, as well as enable and disable them without deleting',
    pageTitle: 'Product Bundles',
    addLabelAreaTitle: 'Highlight your products',
    addLabelAreaSubtitle:
      'Combine different items to sell them together with a discount. Motivate customers to add more products to their carts and spend more money!',
    faqAreaTitle: 'FAQ',
    faqAreaSubtitle: {
      html: 'A detailed guide on how to use an app. If you need any help or have any problems please don\'t hesitate to <a href="https://simcase-apps.omnidesk.ru/?send=question" target="_blank">contact us</a> directly. We are here to help',
    },
    faq: {
      question1: {
        title: 'Promo Item Header',
        content:
          'Advertise and sell your products directly through your page and reach billions of potential customers. Advertise and sell your products directly through your page and reach billions of potential customers.',
      },
    },
    iconableBlockTitle: 'Bundle products',
    iconableBlockDescription:
      'Create bundles that make sense, such as holiday sets or complementary goods combo. Help your customers to find the best addition to their carts and offer the discount for the bundle',
    addNewBundle: 'Create product bundles',
    card: {
      action: 'Actions',
      actionUpdate: 'Edit',
      actionDelete: 'Remove',
    },
    removeBundleModal: {
      label: 'Do you really want to remove this bundle?',
      agreeButton: 'Agree',
      cancelButton: 'Cancel',
    },
  },
  homePage: {
    title: 'Actions',
    addToShopArea: {
      title: 'Add action to the shop',
      subtitle: 'Here you can set the promotion parameters for products.',
      interactiveBlock: {
        title: 'Use FOMO to increase sales',
        subtitle:
          'Create a sense of urgency to motivate your customers to make impulsive purchases and spend more',
        buttonText: 'Set Up Promotion',
      },
    },
    promotionsArea: {
      title: 'Actions list',
      subtitle:
        'You can edit and delete packages, as well as temporarily disable and enable offers.',
      interactiveBlock: {
        emptyBlockTitle: 'Actions list is empty',
        emptyBlockErrorTitle: 'Failed to load actions list',
        actionButton: {
          title: 'Actions',
          actionsList: {
            edit: 'Edit',
            delete: 'Delete',
          },
        },
      },
    },
    shopRevisionArea: {
      title: 'Tailor the store to your needs',
      subtitle:
        'Sometimes just a discount is not enough: you need to tell the buyer which product to add to the cart.',
      interactiveBlock: {
        title: 'Product recommendations',
        subtitle:
          'Help the client collect all the goods for 1+1=3 and offer him accessories, related products or more expensive analogues!',
        buttonText: 'Create action',
      },
    },
    bannerAreaTheFirst: {
      title: 'Useful tool to automate your business',
      subtitle:
        'Set up and manage product ribbons easier than ever! Ribbons are perfect to highlight your new products, items on sale, or bestsellers. Say goodbye to the tedious and time-consuming manual creation of ribbons for each product. Instead, manage them all at once with our app!',
      buttonText: 'Automate ribbons',
    }
  },
  detailPromotionPage: {
    title: 'Settings',
    setupPromotionArea: {
      title: 'Set up the promotion',
      subtitle:
        'Set the discount value and choose the time frames. You can set specific start and end time, or start the promotion immediately, or make it permanent. There are special checkboxes for these options',
      interactiveBlock: {
        section_1: {
          toggleHint: 'Status (Enabled / Disabled)',
          textOf: 'Off',
          textOn: 'On',
        },
        section_2: {
          title_1: 'Promotion',
          parameter: {
            title: 'Discount type',
            option_1: 'percentage',
            option_2: 'fixed amount',
          },
          meaning: {
            title: 'Value',
            hint_1:
              'Value (100% means a customer will get the product for free)',
          },
          title_2: 'Description',
          title_3:
            'Short description (up to 50 characters) will be displayed at checkout, when a customer adds all the products required for the promotion to their cart',
          descriptionPlaceholder: 'Enter a description',
          descriptionError: 'Description must not be empty',
        },
        section_3: {
          title_1: 'Promotion start',
          dateBlock: {
            title_1: 'Time',
            title_2: 'Date',
          },
          checkboxBlock: {
            text: 'Enable the promotion now',
          },
        },
        section_4: {
          title_1: 'Promotion end',
          dateBlock: {
            title_1: 'Time',
            title_2: 'Date',
          },
          checkboxBlock: {
            text: 'Make the promotion permanent',
          },
          error: {
            text: 'End date should be grater than start date',
          },
        },
      },
    },
    setupTimeSlotsArea: {
      mainTitle: 'Choose products for the promotion',
      subMainTitle:
        'Select product categories, or specific products, for the promotion. The discount will be applied according to the schedule you set',
      title: 'Create a schedule ',
      subtitle:
        'You can select one or more time frames to specify start and end dates. You can combine time frames to set the promotion to repeat automatically',
      interactiveBlock: {
        title: 'Promotion Schedule',
        subtitle: 'Add time frames to set start and end dates',
        button: 'Add time slot',
        section_1: {
          title_1: 'Start',
          placeholder_1: 'Time',
          title_2: 'End',
          placeholder_2: 'Date',
        },
        section_2: {
          title_1: 'Promotion repeat',
          option_1: 'Do not repeat',
          option_2: 'Every week - with day selection',
          option_3: 'Every month - ',
          option_4_start: 'Repeat every',
          option_4_ending: 'day of month',
          weekdays: [
            'from sunday every',
            'from monday every',
            'from tuesday every',
            'from wednesday every',
            'from thursday every',
            'from friday every',
            'from saturday every',
          ],
          option_3_ending: ' week',
          daysOptions: [
            'Monday',
            'Tuesday',
            'Wednesday',
            'Thursday',
            'Friday',
            'Saturday',
            'Sunday',
          ],
          title_2: 'Start day',
          daysSelectPlaceholder: 'Select day of the week',
        },
        section_3: {
          title: 'Restrict to',
          checkboxText: 'Do not restrict promotion',
        },
        section_4: {
          question: 'Are you sure you want to delete the time slot?',
          agree: 'Yes',
          cancel: 'No',
        },
      },
    },
    setupPurchaseArea: {
      title: 'Choose products for the promotion',
      subtitle:
        'Select product categories, or specific products, for the promotion. The discount will be applied according to the schedule you set',
      interactiveBlock: {
        productsBinding: {
          title: 'Choose products for the promotion',
          subtitle:
            'Manually select specific products the discount will be applied to',
          buttonAddItemsText: ['items', 'items', 'item'],
        },
        productCategoriesBinding: {
          title: 'Choose product categories for the promotion',
          subtitle: 'Select product categories the discount will be applied to',
        },
      },
    },
    labelsArea: {
      title: 'Product Ribbons',
      subtitle:
        'Draw customers attention to your promotion with special labels. Your ribbons can indicate start and end dates to create a sense of urgency. You can easily customize ribbons by changing their texts and colors',
      interactiveBlock: {
        beforePromotion: {
          title: 'Create a ribbon to display before the promotion',
          description:
            'Create excitement around your store by adding special labels that indicate when the promotion starts',
          inputTitle: 'Ribbon text',
        },
        duringPromotion: {
          title: 'Create a ribbon to display during the promotion',
          description:
            'Show your customers when the promotion ends to encourage them to buy products now',
          inputTitle: 'Ribbon text',
        },
        colorPicker: {
          title: 'Ribbon color',
          customColor: 'Custom Ribbon Color',
        },
        input: {
          inputPlaceholder: "For example, 'Happy Hour'",
          inputLabelColor: 'Color hex code must contain correct value',
        },
        helpTextFinish:
          'The text field can contain only 30 symbols. You can also insert {end_time} and {end_date} variables',
        helpBubbleFinish:
          '{end_time} - promotion end time; {end_date} - promotion end date',
        helpTextStart:
          'The text field can contain only 30 symbols. You can also insert {start_time} and {start_date} variables',
        helpBubbleStart:
          '{start_time} - promotion start time; {start_date} - promotion start date',
        ribbonPreview: 'Ribbon preview',
        deleteRibbon: 'Remove this ribbon',
      },
    },
    FAQ_Area: {
      mainTitle: 'FAQ',
      mainSubtitle:'If you cannot find the answer you are looking for, don\'t hesitate to contact us directly. We are here to help.',
      title: 'Bulk pricing and ‘Happy Hour’: why isn’t my discount applied?',
      subtitle:
        "If you have already set up bulk pricing for your products, be careful when selecting these products in ‘Happy Hour’ app. Discounted price for a ‘Happy Hour’-offer should still be more than any bulk price. Let’s say, the price of the product is $50, if customer buys one item, and it drops to $30 if they buy three items of this product. In this case, you cannot add $20 or more discount to your ‘Happy Hour’ offer that includes the product. Otherwise, the discount will not be applied.",
      substitutions: [
        {
          type: SUBSTITUTION_TYPES.LINK,
          name: 'contact us',
          text: 'contact us',
          payload: {
            href: faqLinks.contactUs,
            target: '_blank',
          },
        },
      ],
    },
  },
};
