import AxiosAPI from './AxiosAPI';
import createServices from './services';
import createRepositories from './repositories';

const apiInstance = new AxiosAPI();
const services = createServices(apiInstance);
const repositories = createRepositories(services);

const api = {
  exampleRepository: repositories.exampleRepository,
  promotionRepository: repositories.promotionRepository,
  ecwidRepository: repositories.ecwidRepository,
};

// example refresh token.
// refresh token async method should always be resolved.
// Depending of the success of the request, the refresh token returns an object of type {status: ok | fail}
//
// apiInstance.setRefreshTokenObject({
//     url: 'refresh-url',
//     request: repositories.exampleRepository.refreshToken
// })

export { apiInstance, services, repositories };
export default api;
