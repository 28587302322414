import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import performBoots from './boot';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { Loader } from '@simcase/simcase-js-sdk';
import App from './App';
import MainPreloader from '../src/components/MainPreloader/MainPreloader';
import store from './store';

(async () => {
    await performBoots();

    const root = document.getElementById('root');
    ReactDOM.render(
        <React.StrictMode>
            <React.Suspense fallback={<Loader />}>
                <Provider store={store}>
                    <App />
                    <MainPreloader />
                </Provider>
            </React.Suspense>
        </React.StrictMode>,
        root
    );
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
