/** @module utils */

/**
 * Returns query parameter value
 * @param {string} param - query parameter name
 * @returns {string}
 * */
function getSearchParams(param) {
  return new URL(window.location.href).searchParams.get(param);
}

export default getSearchParams;
