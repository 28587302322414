import * as Sentry from '@sentry/react';
import { ABORT_ERROR } from '../api/errors/errorTypes';

/**
 * Send exception to Sentry. Send any exception except ABORT_ERROR
 * @memberOf module:utils
 *
 * @param {Error} e - exception
 * */
function sentryCaptureError(e) {
  if (e.type !== ABORT_ERROR) {
    Sentry.captureException(e);
  }
}

export default sentryCaptureError;
