import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  page: {
    composes: 'settings-page',
  },
  footer: {
    height: 40,
  },
  // lastBlockPadding:{
  //   paddingBottom:50,
  // },
});

export default useStyles;
