import SUBSTITUTION_TYPES from "../utils/substituteLocales/substitutionTypes";
import {faqLinks} from "../constants/links";

export default {
  commonTexts: {
    button: {
      save: 'Сохранить',
      cancel: 'Отмена',
      back: 'Назад',
    },
    removeModal: {
      label: 'Вы действительно хотите удалить лейбл?',
      agreeButton: 'Да',
      cancelButton: 'Нет',
    },
    removeModal_HomePage: {
      label: 'Вы действительно хотите удалить акцию?',
      agreeButton: 'Да',
      cancelButton: 'Нет',
    },
    tableProductsHeader: {
      name: 'Название',
      vendorCode: 'Артикул',
      price: 'Цена',
      action: '',
    },
    multiselect: {
      submitButton: 'Добавить к акции',
      cancelButton: 'Отмена',
      selectAllButton: 'Выбрать все',
      selectNoneButton: 'Отменить выделение',
      products: {
        placeholder: 'Название товара, артикул, уникальный код',
        pageText: 'Страница',
        emptyList: {
          title: 'Нет такого товара',
          description:
            'Попробуйте другое название или очистите поиск, чтобы посмотреть все товары',
        },
      },
      categories: {
        placeholder: 'Название категории',
        emptyList: {
          title: 'Нет такой категории',
          description:
            'Попробуйте другое название или очистите поиск, чтобы посмотреть все категории',
        },
      },
    },
  },
  indexPage: {
    bundlesAreaTitle: 'Комплекты магазина',
    bundlesAreaSubtitle:
      'Вы можете редактировать и удалять комплекты, а также временно отключать предложения и включать обратно',
    pageTitle: 'Комплекты',
    addLabelAreaTitle: 'Привлеките внимание к товарам',
    addLabelAreaSubtitle:
      'Объединяйте товары в наборы со скидкой, чтобы Покупатель отправлял в корзину сразу несколько позиций и тратил больше денег в вашем магазине',
    faqAreaTitle: 'FAQ',
    faqAreaSubtitle: {
      html: 'Особенности работы приложения, которые необходимо учитывать при его использовании. Если у вас возникнут вопросы или трудности - <a href="https://simcase-apps.omnidesk.ru/?send=question" target="_blank">свяжитесь с нами</a>, мы обязательно поможем',
    },
    faq: {
      question1: {
        title: 'Promo Item Header',
        content:
          'Advertise and sell your products directly through your page and reach billions of potential customers. Advertise and sell your products directly through your page and reach billions of potential customers.',
      },
      question2: {
        title: 'Promo Item Header',
        content:
          'Advertise and sell your products directly through your page and reach billions of potential customers. Advertise and sell your products directly through your page and reach billions of potential customers.',
      },
    },
    iconableBlockTitle: 'Собрать комплект',
    iconableBlockDescription:
      'Создавайте комплекты, объединенные общей идеей: наборы к праздникам или комплементарные товары. Подскажите Покупателю, что дополнит его корзину, и подкрепите предложение заманчивой скидкой',
    addNewBundle: 'Добавить комплект',
    card: {
      action: 'Действия',
      actionUpdate: 'Редактировать',
      actionDelete: 'Удалить',
    },
    removeBundleModal: {
      label: 'Вы действительно хотите удалить комплект?',
      agreeButton: 'Да',
      cancelButton: 'Нет',
    },
  },
  homePage: {
    title: 'Акции',
    addToShopArea: {
      title: 'Добавить акцию в магазин',
      subtitle: 'Здесь вы можете настроить параметры акции на товары.',
      interactiveBlock: {
        title: 'Ограничьте акцию по времени, чтобы увеличить продажи',
        subtitle:
          'Покупатели совершают импульсивные покупки и тратят больше, когда знают, что скидка скоро закончится',
        buttonText: 'Настроить акцию',
      },
    },
    bannerAreaTheFirst: {
      title: 'Автоматизация бизнеса: новый шаг',
      subtitle:
        'Легко назначайте, меняйте и удаляйте товарные лейблы. Яркие надписи на карточке товара – отличный способ выделить новинки, товары со скидкой или бестселлеры. Забудьте о долгой и изнурительной ручной настройки каждого лейбла. С нашим приложением вы можете управлять лейблами автоматически, и настраивать их для целых категорий!',
        buttonText: 'Автоматизируйте лейблы',
    },
    promotionsArea: {
      title: 'Список акций',
      subtitle:
        'Вы можете редактировать и удалять комплекты, а также временно отключать предложения и включать обратно.',
      interactiveBlock: {
        emptyBlockTitle: 'Список акций пуст',
        emptyBlockErrorTitle: 'Не удалось загрузить список акций',
        actionButton: {
          title: 'Действия',
          actionsList: {
            edit: 'Редактировать',
            delete: 'Удалить',
          },
        },
      },
    },
    shopRevisionArea: {
      title: 'Доработайте магазин под ваши нужды',
      subtitle:
        'Иногда просто скидки недостаточно: нужно подсказать покупателю, какой товар еще добавить в корзину.',
      interactiveBlock: {
        title: 'Товарные рекомендации',
        subtitle:
          'Помогите клиенту собрать все товары для 1+1=3 и предложите ему аксессуары, сопутствующие товары или аналоги подороже!',
        buttonText: 'Создать акцию',
      },
    },
  },
  detailPromotionPage: {
    title: 'Настройка акции',
    setupPromotionArea: {
      title: 'Параметры акции',
      subtitle:
        'Установите размер скидки и время действия акции. Вы можете указать несколько конкретных временных отрезков. Вы также можете начать акцию незамедлительно или сделать ее бесконечной, поставив галочки в соответствующих полях',
      interactiveBlock: {
        section_1: {
          toggleHint: 'Статус (вкл / выкл)',
          textOf: 'Выкл',
          textOn: 'Вкл',
        },
        section_2: {
          title_1: 'Акция',
          parameter: {
            title: 'Тип скидки',
            option_1: 'В процентах',
            option_2: 'Фиксированная сумма',
          },
          meaning: {
            title: 'Значение',
            hint_1: 'Размер (100% – покупатель получит товар бесплатно)',
          },
          title_2: 'Описание',
          title_3:
            'Краткое описание акции (до 50 символов) отобразится в корзине, когда покупатель соберет все необходимые для участия товары',
          descriptionPlaceholder: 'Введите описание',
          descriptionError: 'Описание не должно быть пустым',
        },
        section_3: {
          title_1: 'Начало акции',
          dateBlock: {
            title_1: 'Время',
            title_2: 'Дата',
          },
          checkboxBlock: {
            text: 'Запустить акцию сейчас',
          },
        },
        section_4: {
          title_1: 'Завершение акции',
          dateBlock: {
            title_1: 'Время',
            title_2: 'Дата',
          },
          checkboxBlock: {
            text: 'Сделать акцию бессрочной',
          },
          error: {
            text: 'Дата завершения должна быть больше даты начала',
          },
        },
      },
    },
    setupTimeSlotsArea: {
      title: 'Настроить расписание акции',
      subtitle:
        'При помощи одного или нескольких временных отрезков вы можете установить время начала акции и ее завершение, а также настроить повтор акции – еженедельно или ежемесячно',
      interactiveBlock: {
        title: 'Расписание акции',
        subtitle:
          'Временные отрезки позволяют указать время начала и завершения акции',
        button: 'Добавить слот',
        section_1: {
          title_1: 'Начало акции',
          placeholder_1: 'Время',
          title_2: 'Завершение акции',
          placeholder_2: 'Дата',
        },
        section_2: {
          title_1: 'Повтор акции',
          option_1: 'Не повторять',
          option_2: 'Еженедельно - с выбором дня',
          option_3: 'Ежемесячно - ',
          option_4_start: 'Повторять каждое',
          option_4_ending: 'число месяца',
          weekdays: [
            'с воскресенья кадж.',
            'с понедельника кадж.',
            'со вторника кадж.',
            'со среды кадж.',
            'с четверга кадж.',
            'с пятницы кадж.',
            'с субботы кадж.',
          ],
          option_3_ending: '-й недели',
          daysOptions: [
            'Понедельник',
            'Вторник',
            'Среда',
            'Четверг',
            'Пятница',
            'Суббота',
            'Воскресенье',
          ],
          title_2: 'Стартовый день',
          daysSelectPlaceholder: 'Выберите день',
        },
        section_3: {
          title: 'Ограничить акцию до',
          checkboxText: 'Не ограничивать акцию',
        },
        section_4: {
          question: 'Вы действительно хотите удалить временной слот?',
          agree: 'Да',
          cancel: 'Нет',
        },
      },
    },
    setupPurchaseArea: {
      title: 'Товары, участвующие в акции',
      subtitle:
        'Выберите категории товаров для участия в акции. В указанное время они будут доступны со скидкой. Вы также можете вручную выбрать конкретные товары для участия в акции',
      interactiveBlock: {
        productsBinding: {
          title: 'Выбрать товары',
          subtitle:
            'Вручную привяжите отдельные товары, к которым будет применена скидка',
          buttonAddItemsText: ['товаров', 'товара', 'товар'],
        },
        productCategoriesBinding: {
          title: 'Выбрать категории товаров',
          subtitle:
            'Выберите категории товаров, к которым будет применена скидка',
        },
      },
    },
    labelsArea: {
      title: 'Настройка лейблов',
      subtitle:
        'Добавьте специальные лейблы, чтобы привлечь внимание покупателей. При помощи лейблов вы можете сообщить о начале акции или ее завершении',
      interactiveBlock: {
        beforePromotion: {
          title: 'Добавить лейбл перед началом акции',
          description:
            'Создайте приятные ожидания: добавьте к товарам лейблы, которые сообщат покупателям о приближающейся акции. Вы можете настроить текст и цвет своих лейблов',
          inputTitle: 'Текст лейбла',
        },
        duringPromotion: {
          title: 'Добавить лейбл во время акции',
          description:
            'Укажите, когда началась и когда закончится акция, чтобы подтолкнуть покупателя приобрести товар прямо сейчас',
          inputTitle: 'Текст лейбла',
        },
        colorPicker: {
          title: 'Цвет лейбла',
          customColor: 'Собственный цвет лейбла',
        },
        input: {
          inputPlaceholder: 'Например, «Счастливый час»',
          inputLabelColor: 'Код цвета должен иметь корректное значение',
        },
        helpTextFinish:
          'Текст лейбла не может превышать 30 символов. Вы можете добавить в текст лейбла переменные {end_time} и {end_date}',
        helpBubbleFinish:
          '{end_time} - время окончания акции; {end_date} - дата окончания акции',
        helpTextStart:
          'Текст лейбла не может превышать 30 символов. Вы можете добавить в текст лейбла переменные {start_time} и {start_date}',
        helpBubbleStart:
          '{start_time} - время начала акции; {start_date} - дата начала акции',
        ribbonPreview: 'Предпросмотр лейбла',
        deleteRibbon: 'Удалить лейбл',
      },
    },
    FAQ_Area: {
      mainTitle: 'FAQ',
      mainSubtitle:'Если у вас возникнут вопросы или трудности – свяжитесь с нами, мы обязательно поможем',
      title: 'Товары с оптовыми ценами и скидка в приложении: как избежать конфликта?',
      subtitle:
        'Если вы уже назначили оптовые цены на товары, пожалуйста, будьте внимательны, когда добавляете эти товары в списки акционных. Цена со скидкой по акции все еще должна превышать оптовую цену. Возьмем, к примеру, товар с розничной ценой 1000 рублей и оптовой ценой 800 рублей. Скидка, устанавливаемая в приложении, должна быть меньше 200 рублей. В противном случае и оптовая, и розничная цены останутся прежними, даже если покупатель выполнит все условия акции.',
      substitutions: [
        {
          type: SUBSTITUTION_TYPES.LINK,
          name: 'свяжитесь с нами',
          text: 'свяжитесь с нами',
          payload: {
            href: faqLinks.contactUs,
            target: '_blank',
          },
        },
      ],
    },
  },
};
