import { REQUEST_ERROR, BACKEND_500_ERROR } from './errorTypes';

class RequestError extends Error {
  constructor(body, message) {
    super(message);
    this._type =
      typeof body.status === 'number' ? BACKEND_500_ERROR : REQUEST_ERROR;
    this._body = body;
  }

  get type() {
    return this._type;
  }

  get body() {
    return this._body;
  }
}

export default RequestError;
