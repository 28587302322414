/** @module api */

/**
 * @typedef RefreshTokenObject
 * @type {Object}
 * @property {string} url - refresh token api request url
 * @property {function} request - refresh token method
 * */

/**
 * Class representing an Axios API
 * */
import axios from 'axios';
import { camelToSnake, snakeToCamel } from '../DTOs/TransformCase';
import RequestError from './errors/RequestError';
import ResponseError from './errors/ResponseError';
import AbortError from './errors/AbortError';

class AxiosAPI {
  _baseURL;
  _axiosInstance;
  _refreshTokenObject = null;
  _accessToken = {
    bearer: 'Bearer',
    token: '',
  };
  /**
   * Create Axios Api instance
   *
   * @param {string} [baseURL=''] - Base API url
   * @param {Object} [baseParams] - Base request parameters
   * */
  constructor(baseURL = '', baseParams) {
    this._baseURL = baseURL;
    this._baseParams = baseParams;
    this._axiosInstance = axios.create({
      baseURL: baseURL,
      // Временно
      headers: { 'Accept-Language': 'ru' },
    });
    this._initInterceptors();
  }
  /**
   * Send request
   *
   * @async
   * @param {Object} data - Request payload
   * */
  async request(data) {
    return await this._axiosInstance(data);
  }

  /**
   * Returns access token
   *
   * @param {boolean} withBearer - Should access token starts with Bearer
   * @returns {string}
   * */
  getAccessToken(withBearer) {
    return `${withBearer ? `${this._accessToken.bearer}` : ''}${this._accessToken.token}`;
  }

  /**
   * Sets access token
   *
   * @param {string} accessToken - Access token
   * */
  setAccessToken(accessToken) {
    this._accessToken.token = accessToken;
    this._axiosInstance.defaults.headers.common['Authorization'] = this.getAccessToken(true);
  }

  /**
   * Remove access token
   * */
  removeAccessToken() {
    this._accessToken.token = '';
    this._axiosInstance.defaults.headers.common['Authorization'] = '';
  }

  /**
   * Returns base api url
   *
   * @returns {string}
   * */
  get baseURL() {
    return this._baseURL;
  }

  /**
   * Sets base api url
   *
   * @param {string} value - Базовый api url
   * */
  set baseURL(value) {
    this._baseURL = value;
    this._axiosInstance.defaults.baseURL = value;
  }

  /**
   * Returns base request parameters
   *
   * @returns {Object}
   * */
  getBaseParams() {
    return this._baseParams;
  }

  /**
   * Set base request parameters
   *
   * @param {Object} value - Base request parameters
   * */
  setBaseParams(value) {
    this._baseParams = value;
  }

  /**
   * Add base request parameters to existing ones
   *
   * @param {Object} value - Request parameters
   * */
  addBaseParams(value) {
    this._baseParams = { ...this._baseParams, ...value };
  }

  /**
   * Sets object containing unnecessary data for refreshing access token
   *
   * @param  {RefreshTokenObject} refreshToken - Object containing unnecessary data for refreshing access token
   * */
  setRefreshTokenObject(refreshToken) {
    this._refreshTokenObject = refreshToken;
  }

  /**
   * Returns object containing unnecessary data for refreshing access token
   *
   * @returns {RefreshTokenObject|null}
   * */
  getRefreshTokenObject() {
    return this._refreshTokenObject;
  }

  /**
   * Removes object containing unnecessary data for refreshing access token
   * */
  removeRefreshTokenObject() {
    this._refreshTokenObject = null;
  }

  /**
   * Initializes middleware for requests and responses
   * */
  _initInterceptors() {
    this._successResponseInterceptor = this._successResponseInterceptor.bind(this);
    this._failureResponseInterceptor = this._failureResponseInterceptor.bind(this);
    this._requestInterceptor = this._requestInterceptor.bind(this);
    this._axiosInstance.interceptors.response.use(
      this._successResponseInterceptor,
      this._failureResponseInterceptor
    );
    this._axiosInstance.interceptors.request.use(this._requestInterceptor);
  }

  /**
   * Middleware called before sending a request.
   *
   * @param {Object} data - Данные запроса
   * */
  _requestInterceptor(data) {
    if (data.headers['Content-Type'] === 'multipart/form-data') return data;
    data.data = camelToSnake(data.data);
    data.params = { ...this._baseParams, ...data.params };
    return data;
  }

  /**
   * Middleware called after getting success response
   * */
  _successResponseInterceptor(response) {
    if (response.headers['content-type'] === 'application/json') {
      const { data, meta = null } = response.data;
      const transformedResponse = snakeToCamel({ data, meta });
      return Promise.resolve(transformedResponse);
    } else {
      return Promise.resolve(response);
    }
  }

  // Dumb fix
  static advancedOrMessage(e) {
    const advancedArray = e.advanced[Object.keys(e.advanced)[0]];
    return advancedArray?.[0] ?? e.message;
  }

  /**
   * Middleware called after getting failure response
   * */
  async _failureResponseInterceptor(error) {
    let errorBody;
    let returnedError;
    try {
      if (error.name === 'CanceledError') {
        returnedError = new AbortError(error.message);
      } else if (!error.response || error.response.status === 500) {
        const status = error.response?.status ? 500 : null;
        const message = error.response ? 'Потеря соединения' : 'Ошибка сервера';
        errorBody = { status };
        returnedError = new RequestError(errorBody, message);
      } else {
        const { data, status } = error.response;
        const { code, message, advanced } = data.error;
        const transformedAdvanced = snakeToCamel(advanced);

        const humanReadableMessage = AxiosAPI.advancedOrMessage({
          message,
          advanced,
        });

        errorBody = { status, code, advanced: transformedAdvanced };
        returnedError = new ResponseError(errorBody, humanReadableMessage);
      }
      switch (returnedError.body.status) {
        case 401: {
          const isRefreshTokenRequest =
            error.response?.config.url === this._refreshTokenObject?.url;
          if (!isRefreshTokenRequest && this._refreshTokenObject) {
            const originalRequest = error.config;
            const response = await this._refreshTokenObject.request();
            if (response.status === 'ok') {
              originalRequest.headers.Authorization = this.getAccessToken(true);
              try {
                originalRequest.data = JSON.parse(originalRequest.data);
                return await this._axiosInstance.request(originalRequest);
              } catch (e) {
                return Promise.reject(e);
              }
            } else {
              returnedError = new ResponseError(
                { status: 401, advanced: {} },
                'Ошибка авторизации'
              );
            }
          }
          break;
        }
        // no default
      }
      return Promise.reject(returnedError);
    } catch (e) {
      errorBody = {
        status: null,
      };
      returnedError = new RequestError(errorBody, 'Непредвиденная ошибка в обработке ответа');
      return Promise.reject(returnedError);
    }
  }
}
export default AxiosAPI;
