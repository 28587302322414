import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Loader } from '@simcase/simcase-js-sdk';
import cx from 'classnames';
import useStyles from './useStyles';
import useStylesPage from '../../pages/styles';

function MainPreloader() {
  const { isDisplayed } = useSelector((state) => state.mainPreloaderReducer);
  const classes = useStyles();
  const classesPage = useStylesPage();

  const mainPreloaderClassName = useMemo(() => {
    return cx(classes.mainPreloaderContainer, classesPage.page);
  }, []);

  if (!isDisplayed) {
    return null;
  }

  return (
    <div className={mainPreloaderClassName}>
      <Loader />
    </div>
  );
}

export default MainPreloader;
